import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import FullWidthLayout from '../layouts/FullWidthLayout';
import SEO from '../components/seo';
import parseHtml from '../helpers/parseHtml';
import usePageRehydrate from '../hooks/usePageRehydrate';

const FullWidthPage = ({ data: { page, otherPage } }) => {
  // const pageData = page || otherPage;
  const pageData = usePageRehydrate(page || otherPage);
  return (
    <FullWidthLayout align="left" title={pageData.metaTitleFormatted}>
      <SEO
        id={pageData.foreignId}
        title={pageData.metaTitleFormatted}
        description={pageData.metaDescription}
        ogImage={pageData.ogImage}
        metaTags={pageData.metaTags}
        slug={pageData.slug}
      />
      {parseHtml(pageData.content)}
    </FullWidthLayout>
  );
};

FullWidthPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
    otherPage: PropTypes.object,
  }).isRequired,
};
FullWidthPage.defaultProps = {};

export default FullWidthPage;

export const query = graphql`
  query getFullWidthPage($slug: String!) {
    page(slug: {eq: $slug}) {
      slug
      title
      parentId
      metaTitleFormatted
      metaDescription
      metaTags
      menuTitle
      id
      foreignId
      content
      className
    }
    otherPage(slug: {eq: $slug}) {
      slug
      title
      parentId
      metaTitleFormatted
      metaDescription
      metaTags
      menuTitle
      id
      foreignId
      content
      className
    }
  }`;
